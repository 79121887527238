import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleCollapse(event) {
    syllabusTree.collapseDeep();
  }

  handleExpandAll(event) {
    syllabusTree.expandDeep();
  }

  handleExpandSelected(event) {
    syllabusTree.collapseDeep();
    syllabusTree.checked().expandParents();
  }
}
