import { Modal } from "bootstrap";

Turbo.setConfirmMethod((message, element) => {
  const dialog = document.getElementById('turbo-confirm-modal')
  dialog.querySelector('p.text-danger').textContent = message

  const modal = new Modal(dialog)
  modal.show()

  return new Promise((resolve, reject) => {
    modal._element.querySelectorAll(".confirm-button").forEach((button) => {
      button.addEventListener("click", () => {
        resolve(button.value == "confirm");
        modal.hide();
      }, {once: true});
    });
  })
})