import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleSelectAll(event) {
    const isActiveSelectAll = event.target.checked  || false;
    const btn = document.querySelector('.add-qs-btn');

    btn.disabled = !isActiveSelectAll;

    $('.js-add_questions_to_teacher_test').prop('checked', isActiveSelectAll);
  }
}
