import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    let backdrop = document.querySelector(".modal-backdrop")
    if (backdrop) {
      backdrop.remove()
    }
    
    this.modal = new Modal(this.element)
    this.modal.show()
    this.element.addEventListener('hidden.bs.modal', (event) => {
      this.element.remove()
    })
    // Auto focus first form input in the modal
    let autoFocusTarget = $(this.element).find('input:text, textarea').first()
    if (autoFocusTarget) { setTimeout(() => { autoFocusTarget.focus() }, 500) }
  }

  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    this.modal.hide()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.modal.hide()
    }
  }

  // hide existing modal on frame before render new
  // action: "turbo:before-render->turbo-modal#hideBeforeRender"
  hideBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }
}
