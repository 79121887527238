import { put } from "@rails/request.js"

// Create global namespace
if (typeof IbQuestionbank === 'undefined') {
  global.IbQuestionbank = {};
}

IbQuestionbank.TeacherTest = {

  // Update view options on server so that DOCX and PDF exports use the correct
  // options.
  updateExportDownloadLinkViewOptions: function(){
    var updateViewOptionsUrl = $('.js-pdf_download_link').data('update-view-options-url');
    var checkedOptions = $.map($('.js-view_options_control:checked'), function(e) { 
      return $(e).val();
    });

    put(updateViewOptionsUrl,
      {
        body: {
          teacher_test: {view_options: checkedOptions},
          turbo_stream_target: "empty"
        },
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      }
    ).then(r => r.text)
      .then(html => Turbo.renderStreamMessage(html))
  }

};

IbQuestionbank.Filterrific = {

  submitFilterForm: function(){
    var form = $(this).parents("form"),
        url = form.attr("action");
    // Submit ajax request
    $.ajax({
      url: url,
      data: form.serialize(),
      type: 'GET',
      dataType: 'script'
    });
  }

};

IbQuestionbank.Formulae = {

  typeSet: function(){
    MathJax.typeset();
  }

};

IbQuestionbank.PatchBootstrap = {

  // Adds an afterShow callback to popover. Use like so:
  // $(".js-popover").popover({
  //   afterShow: IbQuestionbank.Formulae.typeSet
  // });
  addAfterShowCallbackToPopover: function(){
    var showOrig = $.fn.popover.Constructor.prototype.show;
    $.fn.popover.Constructor.prototype.show = function () {
      showOrig.call(this);
      if (this.options.afterShow) {
        this.options.afterShow();
      }
    }
  },

  // Dyncamically computes a popover's placement depending on where on the
  // page the trigger element is. Note: this is not mouseposition on screen,
  // but the position of the element on the page. Ideally I would like mose
  // position, however that requires more research.
  // Use like so:
  // $(".js-popover").popover({
  //   placement: IbQuestionbank.PatchBootstrap.computePopoverPlacement,
  // });
  computePopoverPlacement: function(context, source){
    var position = $(source).position();
    if (position.top < 600){
      return "bottom";
    }
    return "top";
  }

}

IbQuestionbank.UI = {
  // Put all hooks for on dom loaded event here
  onDoomLoaded: function() {
    // Patch Bootstrap's popover to allow a callback afterShow
    IbQuestionbank.PatchBootstrap.addAfterShowCallbackToPopover();

    // Activate auto_complete fields
    $('.js-autocomplete').each(function(){
      var self = $(this);
      self.autocomplete({ source: self.attr('data-endpoint') });
    });

    // Activate bootstrap tooltips
    $("[data-toggle=tooltip]").tooltip();

    // Submit filterrific form when user hits enter in the search field
    $("#manual_filterrific_filter").keypress(function( event ) {
      if(event.which == 13) {
        event.preventDefault();
        $('#manual_filterrific_filter').submit();
      }
    });

    // Use modified filterrific form submission function on manual teacher's
    // filter form so that the ajax spinner works
    $('#manual_filterrific_filter').submit(function() {
      var form = $('#manual_filterrific_filter'),
          url = form.attr("action");
      // turn on spinner
      $('.filterrific_spinner').removeClass('d-none');
      $('.filterrific_spinner').addClass('d-inline');
      // Submit ajax request
      $.ajax({
        url: url,
        data: form.serialize(),
        type: 'GET',
        dataType: 'script'
      }).done(function( msg ) {
        $('.filterrific_spinner').removeClass('d-inline');
        $('.filterrific_spinner').addClass('d-none');
      });
      return false;
    });

    $(document).on('submit', 'form.prevent_submit', function(e) {
      e.preventDefault();

      return;
    });

    $(document).on('click', '.do-print', function(e) {
      e.preventDefault();

      window.print();
      return false;
    });
  }
}
