import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import Sortable from 'sortablejs';

// Allows cloning of list elements. Works in combination with dnd_clone_target controller.
// The example below also allows sorting of items in the target container (after they have been cloned)
// because it provides the "dnd-sort-url" data attribute.
//     .col-md-4
//       %h1 Drag and Drop clone demo
//       %p You can clone items from the list on the left to the one on the right.
//       %ul{data: {controller: "dnd-clone-source"}}
//         - 10.times.each do |idx|
//           %li.dnd-clone-item{data:{"dnd-clone-url": "/clone-item/#{idx}",
//                                    "dnd-sort-url": "/sort-item/#{idx}"}}
//             .dnd-drag-handle X
//             List item #{idx}
//     .col-md-4.pt-5
//       %p Target container
//       %ul{data: {controller: "dnd-clone-target"}, style: "min-height: 15rem;"}
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 300,
        draggable: ".dnd-clone-item", // Specifies which items inside the element should be draggable
        group: {
          name: "dnd-clone",
          pull: "clone",
          put: false
        },
        handle: ".dnd-drag-handle", // Drag handle selector within list items
        onEnd: this.onEnd.bind(this), // callback drag end
        sort: false, // No sorting in the source container
      }
    );
  }

  onSelectQuestion() {
    const checked = document.querySelectorAll('input.js-add_questions_to_teacher_test:checked');
    const btn = document.querySelector('.add-qs-btn');

    btn.disabled = !checked.length > 0;
  }

  onEnd(event) {
    var payload = {
      new_index: event.newIndex,
      question_node_tree_ids: [event.item.dataset.questionNodeTreeId]
    }
    var cloneUrl = event.to.dataset.dndCloneUrl;
    // This callback also triggers when we drag an element inside of the dnd-clone-source. We only want to
    // submit the request if we drop it on the dnd-clone-target.
    if (event.to.dataset.dndCloneTarget) {
      post(cloneUrl,
        {
          body: JSON.stringify(payload),
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        }
      )
    }
  }
}
