import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 300,
        draggable: '.sort-item',
        fallbackOnBody: true,
		    swapThreshold: 0.65,
        group: {
          name: "folders"
        },
        handle: ".sort-handle",
        onEnd: this.onEnd.bind(this),
        onMove: this.onMove.bind(this),
      }
    );

    console.log('TeacherMoveFolders controller connected');
  }

  select(event) {
    var row = $(event.target).parent();

    $(event.target).parents('.teacher-test-folders').find('.row').removeClass('selected')
  
    var create_link = $(event.target).parents('.modal').find('.btn-create-folder')
    var create_href = this.replaceUrlParam(create_link.attr('href'), "parent_id", row.data('id'))

    row.addClass('selected');
    create_link.attr('href', create_href);
  }

  replaceUrlParam(url, paramName, paramValue){
    var pattern = new RegExp('(\\?|\\&)('+paramName+'=).*?(&|$)')
    var newUrl=url
    if(url.search(pattern)>=0){
        newUrl = url.replace(pattern,'$1$2' + paramValue + '$3');
    }
    else{
        newUrl = newUrl + (newUrl.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue
    }
    return newUrl
  }

  onEnd(event) {
    this.setNewDepth(event.item, event);
  }

  onMove(event) {
    console.log(event);
    this.setNewDepth(event.dragged, event);
  }

  setNewDepth(item, event) {
    var depth = $(event.to).data('depth')
    var name_col = $(item).find("[class*='depth-']")

    name_col.removeClass(function (index, css) {
      return (css.match (/\bdepth-\S+/g) || []).join(' ')
    });
    name_col.addClass(`depth-${depth}`)
  }
}