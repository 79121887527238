// This controller triggers a request when the connected select input's value changes
// NOTE: url_value has to be an absolute URL!
// Use like so:
// %div{data: {controller:"select-change",
//             select_change: {url_value: select_exam_publishing_questionbank_question_nodes_url(questionbank),
//                             param_value: "exam_id"}}}
//   .header
//     %h3.mt-3 Exam
//   = select_tag(:exam_id,
//                options_for_select(Exam.id_options_for_select(questionbank), selected: exam.id),
//                class: "form-select",
//                data: {action: "change->select-change#onChange"})
import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    param: String,
    url: String,
  }

  onChange(event) {
    let urlWithParams = new URL(this.urlValue);
    urlWithParams.searchParams.append(this.paramValue, event.target.selectedOptions[0].value)
    get(urlWithParams.href, {responseKind: "turbo-stream"})
  }
}
