/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("jquery")

import "@hotwired/turbo-rails";
import "@rails/request.js";

import "../vendor/jquery-migrate-3.0.0.min";
import "../vendor/jquery-ui-1.9.2.custom";
import "../vendor/chosen.jquery";
import "../vendor/jquery-smooth-scroll";
import "../vendor/filterrific-jquery.js";
// import "../vendor/mml-chtml.js";

import "popper.js";

window.InspireTree = require("inspire-tree");

window.InspireTreeDOM = require("../vendor/inspire-tree-dom.js");

import * as bootstrap from "bootstrap";
import {} from 'jquery-ujs';

import "../controllers";
import "../utils";

import "../src/ib_questionbank";
import "../src/on-dom-loaded";


