import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";
import Sortable from 'sortablejs';

// Allows cloning of list elements. Works in combination with dnd_source_target controller.
export default class extends Controller {
  connect() {
    this.element.dataset.dndCloneTarget = true;
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 300,
        group: {
          name: "dnd-clone",
          put: true,
          pull: false
        },
        handle: ".dnd-drag-handle", // Drag handle selector within list items
        onEnd: this.onEnd.bind(this), // callback drag end
        scroll: true,
        bubbleScroll: true,
        forceAutoScrollFallback: true, // force autoscroll plugin to enable even when native browser autoscroll is available
        scrollSensitivity: 200, // px, how near the mouse must be to an edge to start scrolling.
        scrollSpeed: 15, // px, speed of the scrolling
      }
    );
  }

  // This onEnd callback is only needed if you want to allow sorting inside the target container once an item has
  // been cloned.
  onEnd(event) {
    var payload = {
      new_index: event.newIndex,
      reported_old_index: event.oldIndex
    }
    var sortUrl = event.item.dataset.dndSortUrl;
    put(sortUrl,
      {
        body: JSON.stringify(payload),
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      }
    )
  }
}
