// Periodically refreshes the `turboFrame` with contents of `url` at `intervalMSec`.
import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    intervalMSec: Number,
    turboFrame: String,
    url: String
  }

  connect() {
    this.timeout = setTimeout(() => {
      Turbo.visit(this.urlValue, { frame: this.turboFrameValue })
    }, this.intervalMSecValue);
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
