import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";
import Sortable from 'sortablejs';

// Allows sorting of list elements.
// HAML example:
//     %h1 Drag and Drop sort demo
//     %p You can sort the items in the list below.
//     %ul{data: {controller: "dnd-sort", "dnd-sort":{"containment-value":"dnd-sort-container-17"}}}
//       - 10.times.each do |idx|
//         %li.dnd-sort-item{data:{"dnd-sort-url": "/sort-item/#{idx}"}}
//           .dnd-drag-handle X
//           List item #{idx}
export default class extends Controller {
  static values = { containment: String }

  connect() {
    this.sortable = Sortable.create(
      this.element,
      {
        animation: 300,
        draggable: ".dnd-sort-item", // Specifies which items inside the element should be draggable
        group: this.containmentValue, // Contains droppable area
        handle: ".dnd-drag-handle", // Drag handle selector within list items
        onEnd: this.onEnd.bind(this), // callback drag end
        scroll: true,
        bubbleScroll: true,
        forceAutoScrollFallback: true, // force autoscroll plugin to enable even when native browser autoscroll is available
        scrollSensitivity: 200, // px, how near the mouse must be to an edge to start scrolling.
        scrollSpeed: 15, // px, speed of the scrolling
      }
    );
  }

  onEnd(event) {
    var payload = {
      new_index: event.newIndex,
      reported_old_index: event.oldIndex
    }
    var sortUrl = event.item.dataset.dndSortUrl;
    put(sortUrl,
      {
        body: JSON.stringify(payload),
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      }
    )
  }
}
