// Hacky way to do redirects with turbo streams (from select_change_controller)
// <div data-controller="turbo-stream-redirect" data-turbo-stream-redirect-url-value="<%= path %>"></div>
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  connect () {
    console.log(this.urlValue)
    window.location.href = this.urlValue
  }
}
