import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Adds selected questions to test
export default class extends Controller {
  static values = {
    url: String,
  }

  addSelectedQuestions() {
    var checked = document.querySelectorAll('input.js-add_questions_to_teacher_test:checked');
    var questionNodeIds = Array.from(checked).map(c => c.value);
    this.submitRequest(questionNodeIds);
  }

  async submitRequest(questionNodeIds) {
    const btn = this.element.querySelector('.btn');
    const defaultBtnHtml = btn.innerHTML;

    btn.disabled = true;
    btn.innerHTML = btn.dataset.pleaseWaitText || 'Please wait...';

    const response = await post(
      this.urlValue,
      {
        body: JSON.stringify({question_node_tree_ids: questionNodeIds}),
        responseKind: "turbo-stream"
      }
    )
    if (response.ok) {
      const addToTestCheckboxes = document.querySelectorAll('input.js-add_questions_to_teacher_test');
      addToTestCheckboxes.forEach(checkbox => { checkbox.checked = false; });
      const toggler = document.querySelector('#toggler');
      toggler.checked = false;
    }
    btn.innerHTML = defaultBtnHtml;
    btn.disabled = false;
  }
}
